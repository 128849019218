import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ModalDelete(props) {


  return (
    <Dialog
open={props.openDeleteDialog}
onClose={props.handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>
<DialogTitle id="alert-dialog-title">{props.deleteRowData && (`Cancelar el psicotécnico de "${props.deleteRowData.fields.summary}"?` )}</DialogTitle>
<DialogContent>
 <DialogContentText id="alert-dialog-description">
 Se solicitará la cancelación del psicotécnico. Realmente desea cancelarlo?
  </DialogContentText>
</DialogContent>
<DialogActions>
 <Button onClick={() => props.handleClose()} color="primary">
   Cancelar
 </Button>
 <Button disabled={props.isLoading} onClick={() => {

  props.handleDelete(props.deleteRowData)
  }} color="primary" autoFocus>
   Aceptar &nbsp;
   {
     props.isLoading && <CircularProgress size={'1rem'} />
   }
 </Button>

</DialogActions>
</Dialog>

  );

}
